import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Swagger = makeShortcode("Swagger");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "privacy"
    }}>{`Privacy`}</h1>
    <h2 {...{
      "id": "customer-data"
    }}>{`Customer data`}</h2>
    <p>{`It is currently not possible for a customer to fetch the data Entur has registered on them automatically.
Instead they must contact customer service for assistance to do this.`}</p>
    <h2 {...{
      "id": "deletion"
    }}>{`Deletion`}</h2>
    <p>{`Deletion of profiles is available through the Customers API.`}</p>
    <Swagger url="https://api.dev.entur.io/api-docs/customers-api" operationIds={["deactivateCustomerByCustomerNumber"]} shouldGroupEndpointsByTags={false} mdxType="Swagger" />
    <p>{`By default, the profile will be deleted within 72 hours. The expiration date can be set manually using the `}<inlineCode parentName="p">{`expirationDate`}</inlineCode>{` query parameter.
When a profile is deactivated this way, the owning organisation will receive a `}<inlineCode parentName="p">{`CustomerChanged`}</inlineCode>{` event with `}<inlineCode parentName="p">{`type=UPDATE`}</inlineCode>{`.
The owning organisation is responsible for any follow-up regarding the customer. No mail or messages are sent from Entur.`}</p>
    <p>{`Expired profiles are deleted regularly.
When the profile is deleted, the owning organisation will receive a `}<inlineCode parentName="p">{`CustomerChanged`}</inlineCode>{` event with `}<inlineCode parentName="p">{`type=DELETE`}</inlineCode>{`.
Once a profile has been deleted, there is no personal identifiable data in the customers database.
The `}<inlineCode parentName="p">{`customerNumber`}</inlineCode>{` is kept as a key, so future lookups (for instance based on past sales) will see that a profile used to be registered with this number, but not who it was.
Temporary profiles are deleted in the same way.`}</p>
    <p>{`As a special case - whenever a refund is made, the law requires us to persist the name of the recipient for at least 5 years.
In these cases, the profile information is copied to our accounting systems on the day of the refund, and not automatically deleted until the statutory period has elapsed.`}</p>
    <h5 {...{
      "id": "loyalty-programs"
    }}>{`Loyalty programs`}</h5>
    <p>{`A loyalty program is the connection between a customer and a product that gives this customer an entitlement.
Examples of such entitlements are Customer cards and Personnel tickets.`}</p>
    <p>{`For a given customer, the client can ask to find out which products are available to be used in the purchasing process.
The client should then allow the user to select which loyalty programs they wish to use for which travellers.
The result of this selection is then added to the call to offers, which then applies the products to the result.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      